<template>
  <div class="custom-tree-select-root" style="width: 280px; display: flex">
    <div class="root-title">
      坐席分组
    </div>
    <a-tree-select v-model="selectedTreeData"
                   show-search
                   placeholder="请选择"
                   search-placeholder="查找坐席组"
                   allow-clear
                   tree-default-expand-all
                   :filter-tree-node="filterTreeNode"
                   tree-node-filter-prop="nodeName"
                   dropdown-class-name="custom-tree-select-dropdown"
                   class="custom-tree-select"
                   style="width: 100%">

      <!--level1-->
      <a-tree-select-node v-for="(level1) in treeData"
                          :key="level1.id"
                          :value="level1.id"
                          :title="level1.nodeName">
        <template #title>
          <img :src="GroupSvg" alt="" class="group-icon"/>
          <span>{{ level1.nodeName }}</span>
        </template>

        <!--level2-->
        <a-tree-select-node v-for="(level2) in level1.children"
                            :key="level2.id"
                            :value="level2.id"
                            :title="level2.nodeName">
          <!--level3-->
          <a-tree-select-node v-for="(level3) in level2.children"
                              :key="level3.id"
                              :value="level3.id"
                              :title="level3.nodeName">
            <!--level4-->
            <a-tree-select-node v-for="(level4) in level3.children"
                                :key="level4.id"
                                :value="level4.id"
                                :title="level4.nodeName">
              <!--level5-->
              <a-tree-select-node v-for="(level5) in level4.children"
                                  :key="level5.id"
                                  :value="level5.id"
                                  :title="level5.nodeName">
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select-node>
      </a-tree-select-node>

    </a-tree-select>
  </div>
</template>

<script>
import treeSelectData from './json/treeSelectData.json';

export default {
  name: '',
  watch: {
    selectedTreeData(val) {
      this.$emit('change', val);
    },
  },
  data: () => ({
    selectedTreeData: undefined,
    treeData: JSON.parse(JSON.stringify(treeSelectData)),
  }),
  methods: {
    filterTreeNode(inputValue, treeNode) {
      // return treeNode?.data?.scopedSlots?.title()?.[1]?.children?.[0]?.text?.includes(inputValue) || false;
      return treeNode?.data?.props?.title?.includes(inputValue) || false;
    },
  },
};
</script>

<style scoped lang="scss">
.custom-tree-select-root {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;

  &:hover {
    border-color: #2F54EB;
  }

  .root-title {
    width: 100px;
    line-height: 22px;
    margin: 5px 0;
    border-right: solid 2px #eee;
    text-align: center;
  }
}

/deep/ .custom-tree-select {
  border: 0;
  outline: 0;

  .ant-select-selection:focus,
  .ant-select-selection {
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }
}

.group-icon {
  width: 14px;
  height: 14px;
  margin: 5px;
  color: rgb(153, 153, 153);
  font-size: 14px;
}
</style>

<style lang="scss">
.custom-tree-select-dropdown {
  width: 480px;
  border: 0;
  outline: 0;

  .ant-select-selection:focus,
  .ant-select-selection {
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }

  .ant-select-dropdown-menu-item-disabled:hover {
    cursor: default !important;
    user-select: auto !important;
  }

  .ant-select-dropdown-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 11px;
    border-bottom: 1px solid rgb(230, 235, 242);

    &::before {
      flex: none;
      display: inline-flex;
      content: '';
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background-image: url("../assets/img/search.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .ant-select-search__field__wrap {
      flex: 1 1 1px;
    }

    input.ant-select-search__field {
      padding: 0;
      border: none;
    }

    .ant-select-search__field__placeholder {
      left: 0;
    }
  }

  .ant-select-tree {
    overflow: auto;
    height: 256px;
    margin-top: 8px;
    margin-bottom: 8px;

    li {
      margin: 0;
    }

    .ant-select-tree-node-content-wrapper {
      padding: 4px;

      &::before {
        display: inline-block;
        content: '';
        width: 14px;
        height: 14px;
        margin-right: 4px;
        vertical-align: middle;
        background-image: url("../assets/img/group.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: translateY(-1px);
      }

      &.ant-select-tree-node-selected {
        background-color: rgba(41, 81, 244, 0.05);
        color: #2F54EB;
      }
    }

    // &::-webkit-scrollbar {
    //   width: 8px;
    //   height: 8px;
    //   background-color: #fff
    // }

    // &::-webkit-scrollbar:hover {
    //   display: block
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: #fff
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: rgba(0, 0, 0, 0.5);
    //   border-radius: 8px;
    //   cursor: pointer
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //   background-color: rgba(0, 0, 0, .2)!important;
    // }

  }
}
</style>
