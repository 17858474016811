<template>
  <div
    class="custom-tree-select-mult-root"
    @click="openDropdown"
    @mousedown.prevent
    style="width: 280px; display: flex;"
  >
  
    <div style="width: 100px; line-height: 22px; margin: 5px 0; border-right: solid 2px #eee; text-align: center;">平台店铺</div>
    <a-select
      class="custom-tree-select-multi"
      placeholder="请选择"
      mode="multiple"
      :maxTagCount="1"
      showArrow
      allowClear
      v-model="current"
      default-value="lucy"
      style="width: 100%"
      :open="selectOpen"
      :dropdownStyle="{ width: '522px', height: '336px', overflow: 'auto' }"
      :dropdownMatchSelectWidth="false"
      dropdownClassName="custom-tree-select-multi"
    >
      <div slot="notFoundContent" @click="openDropdown">
        <a-input v-model="searchText" @click="(e) => e.target.focus()" placeholder="请查询平台店铺">
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-divider style="margin: 0;" />
        <div v-if="searchText" class="tree-checkboxgroup">
          <div class="ant-checkbox-group" style="flex: 1;">
            <div v-for="(node, index) in treeDataFilter" :key="index">
              <a-checkbox :indeterminate="getFilterIndeterminate(node)" :checked="getFilterCheck(node)" @change="(val) => onFilterNodeChange(val, node)" class="checkbox-item">
                {{node.filterTitle}}
              </a-checkbox>
            </div>
          </div>
        </div>
        <div v-else class="tree-checkboxgroup">
          <div class="ant-checkbox-group" style="flex: 1; border-right: solid 1px #eee;">
            <div v-for="(node, index) in treeData" :key="index" @mouseover="onMouseOver(node)" 
              @change="onParentChange(node)">
              <a-checkbox :indeterminate="isIndeterminate(node)" :checked="isChecked(node)" class="checkbox-item">
                {{node.title}}
                <a-icon type="right" style="float: right; line-height: 32px;"/>
              </a-checkbox>
            </div>
          </div>
          <div class="ant-checkbox-group" style="flex: 1;">
            <div v-for="(node, index) in parentNode.children" :key="index">
              <a-checkbox :checked="node.checked" @change="(val) => onChange(val, node)" class="checkbox-item">
                {{node.title}}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div slot="clearIcon">
        <a-icon @click="onClear" type="close-circle" theme="filled" />
      </div>
      <div slot="maxTagPlaceholder">
        +{{current.length - 1}}
      </div>
    </a-select>
  </div>
</template>
<script>
const treeData = [
  {
    title: "淘宝",
    children: [
      { title: "天猫儿童旗舰店", checked: false },
      { title: "天猫企业店", checked: false },
      { title: "天猫官方旗舰店", checked: false },
      { title: "天猫品牌店", checked: false },
    ],
  },
  {
    title: "京东",
    children: [
      { title: "京东旗舰店", checked: false },
      { title: "京东官方旗舰店", checked: false },
    ],
  },
];
export default {
  mounted() {
    this.treeData = JSON.parse(JSON.stringify(treeData));
    this.eventHandler = (e) => {
      if(this.eventTarget != e.target) {
        this.selectOpen = false;
      }
    }
    document.body.addEventListener("click", this.eventHandler);
  },
  destroyed() {
    document.body.removeEventListener("click", this.eventHandler);
  },
  data: () => ({
    selectOpen: false,
    
    treeData: [],

    parentNode: treeData[0],

    searchText: "",

    eventTarget: null,

    eventHandler: () => {}

  }),
  computed: {
    treeDataFilter() {
      var arr = [];
      this.treeData.forEach(pNode => {
        pNode.filterTitle = `${pNode.title}`
        arr.push(pNode);
        pNode.children.forEach(cNode => {
          cNode.filterTitle = `${pNode.title}/${cNode.title}`
          arr.push(cNode);
        });
      });
      arr = arr.filter(item => item.filterTitle.includes(this.searchText));
      return arr;
    },
    current: {
      get(){
        var arr = [];
        this.treeData.forEach(p => {
          p.children.forEach(c => {
            if(c.checked) arr.push(c.title)
          })
        })
        return arr;
      },
      set(arr){
        this.treeData.forEach(p => {
          p.children.forEach(c => {
            c.checked = arr.includes(c.title)
          })
        })
      }
    }
  },
  watch: {
    current(val){
      this.$emit("change", val.join(","))
    },
  },
  methods: {
    openDropdown(e){
      this.eventTarget = e.target;
      this.selectOpen = true;
    },
    getFilterCheck(node) {
      if(node.children && node.children.length) {
        return node.children.every(child => child.checked)
      }
      return node.checked;
    },
    getFilterIndeterminate(node) {
      if(node.children && node.children.length) {
        return !(node.children.every(child => child.checked) || node.children.every(child => !child.checked));
      }
      return false;
    },
    onFilterNodeChange(e, node) {
      if(node.children && node.children.length) {
        node.children.forEach(child => child.checked = e.target.checked);
        return
      }
      node.checked = e.target.checked;
    },
    onClear() {
      this.treeData.forEach(p => {
        p.children.forEach(c => {
          c.checked = false;
        })
      })
      this.selectOpen = false;
    },
    onParentChange(node) {
      var children = node.children.filter(child => !this.current.includes(child.title))
      if(children.length > 0){
        children.forEach(child => {
          child.checked = true;
        })
        return;
      }
      node.children.forEach(child => {
        child.checked = false;
      })
    },
    isIndeterminate(node) {
      return !(node.children.every(child => child.checked) || node.children.every(child => !child.checked));
    },
    isChecked(node) {
      return node.children.every(child => child.checked);
    },
    onChange(e, node) {
      node.checked = e.target.checked;
    },
    onMouseOver(e) {
      this.parentNode = e;
    },
  },
};
</script>


<style lang="scss">

.custom-tree-select-mult-root {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;
}
.custom-tree-select-mult-root:hover {
  border-color: #2F54EB;
}
.custom-tree-select-multi {
  border: 0;
  outline: 0;
  
  .ant-select-search {
    display: none;
  }

  .ant-select-selection:focus,
  .ant-select-selection {
    border: 0;
    outline: 0;
    box-shadow: none!important;
  }
  .ant-select-dropdown-menu-item-disabled {
    cursor: default !important;
    user-select: auto !important;
  }
  .ant-select-dropdown-menu-item-disabled:hover {
    cursor: default !important;
    user-select: auto !important;
  }
  .ant-select-dropdown-content{
    height: 100%;
  }
  .ant-select-dropdown-menu-root{
    height: 100%;
    max-height: 100%;
  }
  .ant-select-dropdown-menu{
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .ant-select-dropdown-menu-item{
    margin: 0;
    padding: 0!important;
  }
  .ant-input, .ant-input:focus{
    border: 0;
    box-shadow: none;
  }
  .tree-checkboxgroup{
    height: 300px; 
    display: flex;
  }
  .checkbox-item{
    width: 100%; display: block;
  }
  .ant-checkbox-group{
    padding: 10px 0;
  }
  .ant-checkbox-wrapper{
    margin: 0;
    line-height: 32px;
    padding: 0 14px;
  }
  .ant-checkbox-wrapper:hover{
    background-color: #f3f4f7;
  }

  .ant-select-selection__choice{
    border-radius: 13px;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #2f54eb;
    border-color: #2f54eb;
  }
  .ant-checkbox:hover .ant-checkbox-inner{
    border-color: #2f54eb;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: #2f54eb;
  }
}
</style>